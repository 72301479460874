/**
 * Participants slider on the training page
 */

// Elements
const sliderElement = document.querySelector('.training-detail-page #participants')
const sliderValueElement = document.querySelector('.training-detail-page .price .slider .value')
const totalPriceElement = document.querySelector('.training-detail-page .price .price-total span')
const basePriceElement = document.querySelector('.training-detail-page .price .price-base span')
const submitLinkElement = document.querySelector('.training-detail-page .infos .buttons a')

// Values
const basePrice = +(document.querySelector('.training-detail-page .price')?.dataset.basePrice || 0)
const discountRate = +(document.querySelector('.training-detail-page .price')?.dataset.discountRate || 0)
const duration = +(document.querySelector('.training-detail-page .price')?.dataset.duration || 0)

// Format the given number
function formatNumber(n) {
  if (Intl && Intl.NumberFormat) {
    return Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(n)
  } else {
    return n
  }
}

// Compute the new price
function updatePrice() {

  // Price computation
  const participants = +sliderElement.value
  const discountedPrice = basePrice * Math.pow(discountRate, participants - 1)
  const totalPrice = participants * discountedPrice * duration

  // Update UI
  sliderValueElement.textContent = participants
  totalPriceElement.textContent = formatNumber(totalPrice)
  basePriceElement.textContent = formatNumber(discountedPrice)

  // Update submit URL
  const submitURL = new URL(submitLinkElement)
  submitURL.searchParams.set('participants', participants)
  submitLinkElement.href = submitURL
}

// Listeners
sliderElement?.addEventListener('change', updatePrice)
sliderElement?.addEventListener('input', updatePrice)

// Fire at load
if (sliderElement) {
  updatePrice()
}
