/**
 * Projects widget on the home page
 */

// Queries
const allProjects = () => document.querySelectorAll('.home-page .home-projects-list-desktop .project')
const allPreviews = () => document.querySelectorAll('.home-page .home-projects-list-desktop .preview')
const previewWithId = (id) => document.querySelector(`.home-page .home-projects-list-desktop .preview[data-id="${id}"]`)

// Setup listeners
allProjects().forEach(element => {
  element.addEventListener('click', function () {

    // Swap selected project
    allProjects().forEach(e => {
      e.classList.remove('selected')
      e.ariaSelected = 'false'
    })
    element.classList.add('selected')
    element.ariaSelected = 'true'

    // Swap project preview
    const projectId = element.dataset.id
    allPreviews().forEach(e => e.hidden = true)
    previewWithId(projectId).hidden = false
  })
})
